import React, { useState, useRef, Fragment, ReactElement } from 'react'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

import {
  Container,
  Main,
  Children,
} from '@rocketseat/gatsby-theme-docs/src/components/Layout/styles'

import {
  Maybe,
  BooleanQueryOperatorInput,
  MdxHeadingMdx,
  Node,
  Scalars,
} from '../../../../types'
import { isPL } from '../../../../util/langGrabber'
import { CookiesProvider } from 'react-cookie'
import favicon from '../../../../images/favicon.ico'

const Sidebar = loadable(() => import('../Sidebar'))
const Header = loadable(
  () => import('@rocketseat/gatsby-theme-docs/src/components/Header'),
)
const Overlay = loadable(
  () => import('@rocketseat/gatsby-theme-docs/src/components/Overlay'),
)
const TableOfContents = loadable(() => import('../Docs/TOC'))
const CookieConsent = loadable(() => import('react-cookie-consent'))
const Helmet = loadable(() => import('react-helmet'))

export default function Layout({
  children,
  disableTableOfContents,
  title,
  headings,
}: {
  children: Array<Node>
  disableTableOfContents: Maybe<BooleanQueryOperatorInput>
  title: Maybe<Scalars['String']>
  headings: Maybe<Array<Maybe<MdxHeadingMdx>>>
}): ReactElement {
  const contentRef = useRef(null)
  const [isMenuOpen, setMenuOpen] = useState(false)
  const disableTOC =
    disableTableOfContents === true || !headings || headings.length === 0

  function handleMenuOpen() {
    setMenuOpen(!isMenuOpen)
  }

  let cookieAccept, cookieDecline, cookieText

  if (isPL()) {
    cookieAccept = 'Akceptuj'
    cookieDecline = 'Odmów'
    cookieText = `Ta strona używa ciastek, by poprawić odbiór i doświadczenia użytkownika. Możesz wybrać 'Odmów', a strona faq.tiktalik.com nie zapisze ciastka na twoim urządzeniu.`
  } else {
    cookieAccept = 'Accept'
    cookieDecline = 'Decline'
    cookieText = `This page uses cookies to improve user experience. By choosing 'Decline', the site faq.tiktalik.com will not store cookies on your device`
  }

  return (
    <Fragment>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <CookiesProvider>
        <Overlay isMenuOpen={isMenuOpen} onClick={handleMenuOpen} />
        <Container>
          <Sidebar isMenuOpen={isMenuOpen} />
          <Main>
            <Header handleMenuOpen={handleMenuOpen} />
            {title && (
              <h1
                css={css`
                  display: none;
                  @media (max-width: 1200px) {
                    display: block;
                  }
                `}
              >
                {title}
              </h1>
            )}
            <Children ref={contentRef}>
              {title && (
                <h1
                  css={css`
                    @media (max-width: 1200px) {
                      display: none;
                    }
                  `}
                >
                  {title}
                </h1>
              )}
              {children}
            </Children>
            <TableOfContents
              headings={headings}
              disableTOC={disableTOC}
              contentRef={contentRef}
            />
          </Main>
        </Container>
        <CookieConsent
          enableDeclineButton
          location="bottom"
          buttonText={cookieAccept}
          declineButtonText={cookieDecline}
          cookieName="tiktalik-gdpr"
        >
          {cookieText}
        </CookieConsent>
      </CookiesProvider>
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disableTableOfContents: PropTypes.bool,
  title: PropTypes.string,
  headings: PropTypes.array,
}

Layout.defaultProps = {
  disableTableOfContents: false,
  title: '',
  headings: null,
}
